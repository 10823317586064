import React, { useState } from "react";
import styled from "styled-components";
import "./index.css";
import loadable from "@loadable/component";
import { ACCORDION_CONTENT } from "../content";

const Header = loadable(() => import("../components/Header"));
const Accordion = loadable(() => import("../components/Accordion"));

const MainStyled = styled.main`
  padding: 5em;
  font-family: "FantasqueSansMono";

  @media screen and (max-width: 600px) {
    padding: 1.5em;
  }
`;

const IndexPage = () => {
  const [isAccordionHidden, setAccordionHidden] = useState(true);

  return (
    <MainStyled onClick={() => setAccordionHidden(false)}>
      <title>Ollie Zheng</title>
      <Header
        text="./ollie-zheng"
        onTypedComplete={() => setAccordionHidden(false)}
      />

      <Accordion content={ACCORDION_CONTENT} isHidden={isAccordionHidden} />
    </MainStyled>
  );
};

export default IndexPage;
