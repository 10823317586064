import { LinkedinLogo } from "phosphor-react";
import React, { ReactElement } from "react";
import styled from "styled-components";

const LinkedInWrapperStyled = styled.div`
  display: inline-flex;
`;

const LinkedInTextStyled = styled.p`
  margin: auto;
`;

export interface AccordionContentInterface {
  title: string;
  content: ReactElement;
}

export const ACCORDION_CONTENT: AccordionContentInterface[] = [
  {
    title: "--about-me",
    content: (
      <>
        <p>I'm a software dev based out of Sydney.</p>
        <p>
          I mostly play w/ Typescript and bash in my day-to-day, however I've had
          the pleasure of dabbling w/ all sorts of tools throughout my career as
          a software dev.
        </p>
        <p>
          I started off my programming career co-founding PosiSense, where we
          created an aged-care IoT product for preventing bed-sores. Over there
          I've done systems, full-stack and even some hardware work. I then
          moved onto the trailblazer BNPL company Deferit where I've mostly
          focused on full-stack work with Django and React. Right now, I'm now
          working with the Aussie payment platform Beem, contributing to API designs
          that are critical for our QR payment platform.
        </p>
        <p>
          Outside of work and coding, I love making homebrew 🍺, playing the
          accordion 🪗 and playing badminton 🏸.
        </p>
      </>
    ),
  },
  {
    title: "--portfolio",
    content: (
      <>
        <p>
          This websites source code is{" "}
          <a href="https://gitlab.com/OllieZheng/website">here</a>!
        </p>
        <p>
          Play my accordion <a href="https://bayan.olliez.au">here</a>!
        </p>
      </>
    ),
  },
  {
    title: "--contact",
    content: (
      <LinkedInWrapperStyled>
        <LinkedInTextStyled>Find me @ </LinkedInTextStyled>
        <div>
          <a href="https://www.linkedin.com/in/ollie-zheng/">
            <LinkedinLogo size={32} color="palevioletred" />
          </a>
        </div>
      </LinkedInWrapperStyled>
    ),
  },
];
